<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Add Education</title>
  </head>
  <body>
    <div class="container">
      <div class="card">
        <div class="card-body">
          <form #educationForm="ngForm" (ngSubmit)="addEducationDetails(educationForm)">
            <div class="row">
              <div class="col-2"></div>
              <div class="col-3">
                <label for="levelEdu">Level Of Education</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    name="levelEdu"
                    class="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    ngModel
                  />
                </div>
              </div>
              <div class="col-2"></div>
              <div class="col-3">
                <label for="field">Field/ Stream</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    name="field"
                    class="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    ngModel
                  />
                </div>
              </div>
            </div>
            <div class="row">
                <div class="col-2"></div>
                <div class="col-3">
                  <label for="schoolEdu">School/ College/ University</label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      name="schoolEdu"
                      class="form-control"
                      aria-label="Sizing example input"
                      aria-describedby="inputGroup-sizing-default"
                      ngModel
                    />
                  </div>
                </div>
                <div class="col-2"></div>
                <div class="col-3">
                  <label for="marks">Marks Obtained</label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      name="marks"
                      class="form-control"
                      aria-label="Sizing example input"
                      aria-describedby="inputGroup-sizing-default"
                      ngModel
                    />
                  </div>
                </div>
              </div>
            <div class="row">
              <div class="col-2"></div>
              <div class="col-3">
                <label for="startDate">Start Date</label>
                <div class="input-group mb-3">
                  <input
                    type="date"
                    name="startDate"
                    class="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    ngModel
                  />
                </div>
              </div>
              <div class="col-2"></div>
              <div class="col-3">
                <label for="endDate">End Date</label>
                <div class="input-group mb-3">
                  <input
                    type="date"
                    name="endDate"
                    class="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    ngModel
                  />
                </div>
              </div>
            </div>
            <div class="row">
                <div class="col-4"></div>
                <div class="col-4">
                  <label for="educationFile">Education</label>
                  <div class="input-group mb-3">
                    <input
                    type="file"
                    name="educationFile"
                    class="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    (change)="onChange($event)"
                    />
                  </div>
                </div>
                <div class="col-4"></div>
              </div>
            <div class="row">
              <div class="col-3"></div>
              <div class="col-2">
                <button class="mx-auto btn btn-outline-primary">Cancel</button>
              </div>
              <div class="col-3"></div>
              <div class="col-2">
                <button [disabled]="!viewLoader" type="submit"  class="mx-auto btn btn-primary">Save</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- <div [hidden]="viewLoader" class="spinner-border positionSpinner"></div> -->
  </body>
</html>
