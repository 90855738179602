import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CRMLeadServiceService } from 'src/app/Services/leadAPI/crmlead-service.service';
import { LeadOffersRoutingModule } from '../../lead-offers-routing.module';
import { environment } from '@env';
import { SocketService } from 'src/app/Services/socketService/socket.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  showNoMessageAlert: boolean = false;
  viewLoader : boolean = true;
  
  constructor(private leadApi: CRMLeadServiceService, 
    private router: Router, 
    private route : ActivatedRoute,
    private socketSerivce : SocketService,
    private toastService: ToastrService) { }
  
  message : string = "";
  apiLink = environment.production;
  file : File;
  loggedUser = localStorage.getItem('user')
  abc : string[] = [];
  leadId : any;
  ngOnInit(){
    this.leadId = this.route.snapshot.params['leadId']
    this.getChat();
  }

  getChat(){
    this.leadApi.getChat(this.leadId).subscribe(
      data =>{ 
          this.abc = data.leadChat
          console.log(this.abc)
      },
      err => console.log(err),
      () => console.log("Get chat executed")
    )
  }
  onChange(event) {
    this.file = event.target.files[0];
    this.addPassportDetails();
  }

  addPassportDetails(){
    this.viewLoader = false;
    const formData = new FormData();
  
    formData.append("file", this.file);
    formData.append("leadId", this.leadId),
    formData.append("accountUserId", localStorage.getItem('userId')),
    formData.append("message", this.file.name)
    this.leadApi.postChatDocument(formData).subscribe(
      data => {console.log(data)
        this.viewLoader = true;
        this.toastService.success('File uploaded in the chat');
      this.ngOnInit()
      },
      err => {
        console.log(err)
        this.viewLoader = true;
      },
      () => console.log("Successfully posted the document")
    )
  }


  addChat(){
    let obj = {
      "leadId":this.leadId,
      "accountUserId": localStorage.getItem('userId'),
      "message":this.message
    }
    if(this.message.length < 1){
      this.showNoMessageAlert = true;
      setTimeout(()=>{
        this.showNoMessageAlert = false;
      }, 2000)
    }
    else{
      this.leadApi.postChat(obj).subscribe(
        data => 
        {
          console.log(data)
          this.getChat()
          this.socketSerivce.socket.emit('notification',{
            leadId : this.leadId,
            accountUserId:localStorage.getItem('userId')
          })
          // this.ngOnInit()
        },
        err=>{
          this.toastService.success(err.message)
        },
        ()=>{
          this.message = ""
        }
      )
    }
  }
}
