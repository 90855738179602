<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>CourseList</title>
  </head>

  <body>
    <div class="container">
      <div class="row">
        <div class="col-3">
          <div class="card">
            <div class="card-header">Downloads</div>
            <div class="card-body">
              <div class="row downloadRow">
                <div class="col-9">
                  956 Form
                </div>
                <div class="col-3" style="text-align: right">
                  <i
                    class="fas fa-cloud-download-alt mr-2 customDownloadIcon"
                    [ngClass]="{ customDownloadIconAnimation: showAnimation }"
                    style="text-align: right"
                    data-toggle="modal"
                    data-target="#exampleModalCenter1"
                    data-backdrop="static"
                    data-keyboard="false"
                  ></i>
                </div>
              </div>
              <div class="row downloadRow">
                <div class="col-9">Client Agreement</div>
                <div class="col-3" style="text-align: right">
                  <i
                    class="fas fa-cloud-download-alt mr-2 customDownloadIcon"
                    style="text-align: right"
                    role="button"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                    data-backdrop="static"
                    data-keyboard="false"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div class="card notesCard">
            <div class="card-header">File Notes</div>
            <div class="card-body notesCardBody">
              <!-- <p>{{ leadNotes }}</p> -->
              <div [innerHTML]="leadNotes"></div>
            </div>
            <div class="card-footer notesCardFooter">
              <textarea
                autocomplete="off"
                #notes
                id="notes"
                name="notes"
                rows="2"
                class="notesTextarea"
                [(ngModel)]="leadNotesNew"
                (keyup.enter)="leadNotesUpdate()"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div class="card">
            <div class="card-header">Lead Details</div>
            <div class="card-body">
              <div class="row">
                <div
                  class="col-4 cursorPointer"
                  (click)="commonEdit('1', lead?.firstName)"
                >
                  <label for="name">First Name</label>
                  <p *ngIf="editableIndex != '1'; else firstNameElse">
                    {{ lead?.firstName }}
                  </p>
                  <ng-template #firstNameElse>
                    <br />
                    <input [(ngModel)]="editableValue" />
                    <button class="saveEditButton" (click)="commonSave('1')">
                      <i class="fas fa-save"></i>
                    </button>
                  </ng-template>
                </div>
                <div
                  class="col-4 cursorPointer"
                  (click)="commonEdit('2', lead?.lastName)"
                >
                  <label for="name">Last Name</label>
                  <p *ngIf="editableIndex != '2'; else lastNameElse">
                    {{ lead?.lastName }}
                  </p>
                  <ng-template #lastNameElse>
                    <br />
                    <input [(ngModel)]="editableValue" />
                    <button class="saveEditButton" (click)="commonSave('2')">
                      <i class="fas fa-save"></i>
                    </button>
                  </ng-template>
                </div>
                <div class="col-4">
                  <label for="dob">Date Of Birth</label>
                  <p id="dob">
                    {{ lead?.dob }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <label for="gender">Gender</label>
                  <p>
                    {{ lead?.prefix }}
                  </p>
                </div>
                <div class="col-4">
                  <label for="name">Marital Status</label>
                  <p>
                    {{ lead?.marriageStatus }}
                  </p>
                </div>
                <div class="col-4">
                  <label for="service">Service</label>
                  <p>
                    {{ lead?.leadToService[0]?.Service.serviceName }}
                  </p>
                </div>
              </div>
              <hr width="100%" />
              <div class="row">
                <div
                  class="col-4 cursorPointer"
                  (click)="commonEdit('3', lead?.emailId)"
                >
                  <label for="name">E-Mail</label>
                  <p *ngIf="editableIndex != '3'; else emailElse">
                    {{ lead?.emailId }}
                  </p>
                  <ng-template #emailElse>
                    <br />
                    <input [(ngModel)]="editableValue" />
                    <button class="saveEditButton" (click)="commonSave('3')">
                      <i class="fas fa-save"></i>
                    </button>
                  </ng-template>
                </div>
                <div
                  class="col-4 cursorPointer"
                  (click)="commonEdit('4', lead?.phoneNumber)"
                >
                  <label for="gender">Mobile No.</label>
                  <p *ngIf="editableIndex != '4'; else phoneElse">
                    {{ lead?.phoneNumber }}
                  </p>
                  <ng-template #phoneElse>
                    <br />
                    <input [(ngModel)]="editableValue" />
                    <button class="saveEditButton" (click)="commonSave('4')">
                      <i class="fas fa-save"></i>
                    </button>
                  </ng-template>
                </div>
              </div>
              <hr width="100%" />
              <div class="row">
                <div
                  class="col-3 cursorPointer"
                  (click)="
                    commonEdit('5', lead?.leadAddresses[0]?.addressLine1)
                  "
                >
                  <label for="name">Address</label>
                  <p *ngIf="editableIndex != '5'; else addressLineElse">
                    {{ lead?.leadAddresses[0]?.addressLine1 }}
                  </p>
                  <ng-template #addressLineElse>
                    <br />
                    <input [(ngModel)]="editableValue" />
                    <button
                      class="saveEditButton"
                      (click)="commonSave('5', lead?.leadAddresses[0]?.id)"
                    >
                      <i class="fas fa-save"></i>
                    </button>
                  </ng-template>
                </div>
                <div
                  class="col-3 cursorPointer"
                  (click)="commonEdit('6', lead?.leadAddresses[0]?.city)"
                >
                  <label for="gender">City</label>
                  <p *ngIf="editableIndex != '6'; else cityElse">
                    {{ lead?.leadAddresses[0]?.city }}
                  </p>
                  <ng-template #cityElse>
                    <br />
                    <input [(ngModel)]="editableValue" />
                    <button
                      class="saveEditButton"
                      (click)="commonSave('6', lead?.leadAddresses[0]?.id)"
                    >
                      <i class="fas fa-save"></i>
                    </button>
                  </ng-template>
                </div>
                <div
                  class="col-3 cursorPointer"
                  (click)="commonEdit('7', lead?.leadAddresses[0]?.state)"
                >
                  <label for="name">State</label>
                  <p *ngIf="editableIndex != '7'; else stateElse">
                    {{ lead?.leadAddresses[0]?.state }}
                  </p>
                  <ng-template #stateElse>
                    <br />
                    <input [(ngModel)]="editableValue" />
                    <button
                      class="saveEditButton"
                      (click)="commonSave('7', lead?.leadAddresses[0]?.id)"
                    >
                      <i class="fas fa-save"></i>
                    </button>
                  </ng-template>
                </div>
                <div
                  class="col-3 cursorPointer"
                  (click)="commonEdit('8', lead?.leadAddresses[0]?.pincode)"
                >
                  <label for="name">Pincode</label>
                  <p *ngIf="editableIndex != '8'; else pinElse">
                    {{ lead?.leadAddresses[0]?.pincode }}
                  </p>
                  <ng-template #pinElse>
                    <br />
                    <input [(ngModel)]="editableValue" />
                    <button
                      class="saveEditButton"
                      (click)="commonSave('8', lead?.leadAddresses[0]?.id)"
                    >
                      <i class="fas fa-save"></i>
                    </button>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div [hidden]="viewLoader" class="spinner-border positionSpinner"></div> -->
    <!-- Button trigger modal -->

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Client Agreement Details
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form
              #EntryForm="ngForm"
              autoComplete="off"
              (submit)="submitForm(EntryForm)"
            >
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label for="city">Agent Fees</label>
                  <input
                    [(ngModel)]="netAmount"
                    autocomplete="off"
                    type="number"
                    name="agentFees"
                    required
                    class="form-control"
                    id="agentFees"
                    (input)="getTotalCharges($event)"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="city">Application Charge</label>
                  <input
                    autocomplete="off"
                    type="number"
                    [(ngModel)]="grossAmount"
                    name="appCharge"
                    required
                    class="form-control"
                    id="appliCharge"
                    (input)="getTotalCharges($event)"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="state">Total</label>
                  <input
                    autocomplete="off"
                    type="number"
                    name="total"
                    [value]="totalCharges"
                    class="form-control"
                    id="total"
                    disabled
                  />
                </div>
                <div class="form-group col-md-2">
                  <label for="state">GST</label>
                  <select class="form-control" (change)="gstOption($event)">
                    <option value="99">Select GST</option>
                    <option value="'true'">Excluding</option>
                    <option value="'false'">Including</option>
                  </select>
                </div>
                <div class="form-group col-md-1">
                  <label for="state">$ or ₹</label>
                  <select class="form-control" (change)="currOption($event)">
                    <option value="99">Curr</option>
                    <option value="$">$</option>
                    <option value="INR">₹</option>
                  </select>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="agent">Select Agent</label>
                  <select
                    class="form-control"
                    (change)="secSelectOption($event)"
                  >
                    <option value="99">Select Agent</option>
                    <option value="0">Kamaljeet Singh</option>
                    <option value="1">Shubham Mahajan</option>
                    <option value="2">Varinder Kaur</option>
                  </select>
                </div>
                <div class="form-group col-md-4">
                  <label for="city">Payment Received</label>
                  <input
                    [(ngModel)]="paymntReceived"
                    autocomplete="off"
                    type="number"
                    name="agentFees"
                    required
                    class="form-control"
                    id="agentFees"
                  />
                </div>
              </div>
              <div class="form-row">
                <button class="btn btn-primary ml-auto mr-auto" type="submit">
                  Download
                  <i
                    class="fas fa-cloud-download-alt mr-2 customDownloadIcon"
                  ></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="exampleModalCenter1" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">
            956 Details
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-row">
            <div class="form-group col-md-4 ml-auto mr-auto">
              <label for="Agent">Agent</label>
              <select class="form-control" (change)="selectOption($event)">
                <option value="99">Select 956 Agent</option>
                <option value="0">Kamaljeet Singh</option>
                <option value="1">Shubham Mahajan</option>
                <option value="2">Varinder Kaur</option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <button class="btn btn-primary ml-auto mr-auto" (click)="modifyPdf()">
              Download
              <i
                class="fas fa-cloud-download-alt mr-2 customDownloadIcon"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    </div>
  </body>
</html>
