<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
</head>
<body>
    <div class="container">
    <div class="card cardParent">
        <ng-container *ngFor="let dataComment of abc">
          <div class="message">
            <div class="row">
              <div class="circle">{{dataComment.accountUser.userEmail | slice : 0: 2}}</div>
              <div class="col-auto my-auto emailSent">{{dataComment.accountUser.userEmail}}</div>
              <div class="col-auto my-auto dateSent ml-auto">{{dataComment.updatedDate | date : 'medium'}}</div>
            </div>
            
            <div [ngClass]="{'blueCardChild': loggedUser == dataComment.accountUser.userEmail, 'redCardChild': loggedUser != dataComment.accountUser.userEmail}" class="card">
                
             <div *ngIf="!dataComment.documentLink"> {{dataComment.message}} </div>
             
                <div *ngIf="dataComment.documentLink" class="inline">
                  Document <a href="{{apiLink}}document?id={{dataComment.documentLink}}" target="_blank">( {{dataComment.message}} )</a>
                  has been uploaded. Please check for your perusal.
                </div>
              </div>
          </div>
        </ng-container>
    </div>
    
    <div class="input-group mb-3">
        <input type="text" id="comment" [(ngModel)]="message" class="form-control" (keyup.Enter)="addChat()" #comment aria-label="Recipient's username" aria-describedby="basic-addon2">
        <div class="input-group-append">
          <span class="input-group-text" id="basic-addon2"><input (change)="onChange($event)" type="file" id="file_upload_id" style="display:none">
            &nbsp;&nbsp;<label for="file_upload_id"><i id="icon_upload" class="fa fa-paperclip fa-xl ml-3 mr-3"  ></i></label>
            </span>
          <span (click)="addChat()" class="input-group-text" id="basic-addon2"><i
            class="fab fa-telegram-plane fa-xl ml-3 mr-3"></i></span>
        </div>
      </div>

      <div *ngIf="showNoMessageAlert" class="alert alert-warning alert-dismissible fade show" role="alert">
        <strong>Holy Moly!</strong> Please enter a message to send.
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
</div>
<!-- <div [hidden]="viewLoader" class="spinner-border positionSpinner"></div> -->
</body>
</html>