
    <div class="container-fluid px-4 pt-4 rounded">
      <div class="card shadow parentCard" style="border-radius: 10px">
        <div class="row marClass">

            <div class="hh-grayBox pt45 pb20 mx-auto">
              <div class="row justify-content-between">
                <div *ngFor="let milestone of lead?.leadToService[0]?.Service.serviceMilestone" class="order-tracking" [ngClass]="{'completed': milestone.milestoneSequenceNumber <= lead?.leadToService[0]?.milestoneSequenceNumber }">
                  <span class="is-complete"></span>
                  <p>{{milestone.milestoneName}}</p>
                </div>
              </div>
            </div>
            <!-- <i class="far fa-comment seenComment">Seen</i> -->
        </div>
        <br/>
        <div class="card shadow parentCard" style="border-radius: 10px">
          <div class="row navRow">
            <div class="col-2"></div>
            <button
              class="btn btn-outline-primary col-2"
              (click)="routeToStudentOverView()"
            >
              Student Overview
            </button>
            <button
              class="btn btn-outline-primary col-2"
              (click)="routeToOfferDoc()"
            >
              offer Letter Documents
            </button>
            <button
              class="btn btn-outline-primary col-2"
              (click)="routeToApplications()"
            >
              Applications Applied
            </button>
            <!-- <button
              class="btn btn-outline-primary col-2"
              (click)="routeToVisaDoc()"
            >
              Visa Documents
            </button> -->
            <!-- <button
              class="btn btn-outline-primary col-2"
              (click)="routeToInvoice()"
            >
              Invoice
            </button> -->
            <button
              class="btn btn-outline-primary col-2"
              (click)="routeToComments()"
            >
              Team Notes
            </button>
            <div class="col-2"></div>
          </div>
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>

    <!-- <div class="text-center">
    <input class="form-control" type="file" (change)="onChange($event)">

    <button (click)="onUpload()" class="btn btn-success">
        Upload
    </button>
</div>

Shareable short link of uoloaded file -->
    <!-- <div class="container text-center jumbotron" *ngIf="shortLink">
    <h2> Visit Here</h2>
    <a href="{{shortLink}}">{{shortLink}}</a>
</div> -->

    <!--Flag variable is used here-->
    <!-- <div class="container" *ngIf="loading">
    <h3>Loading ...</h3>
</div> -->
