import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CourseListLandingComponent } from '../course/course-list-landing/course-list-landing.component';
import { ApplicationsPerUserComponent } from './applications-per-user/applications-per-user.component';
import { AustraliaServiceMovementComponent } from './australia-service-movement/australia-service-movement.component';
import { LeadOffersListComponent } from './lead-offers-list/lead-offers-list.component';
import { CommentsComponent } from './LeadOfferunits/comments/comments.component';
import { DemoFileUploadComponent } from './LeadOfferunits/demo-file-upload/demo-file-upload.component';
import { OfferLetterDocumentsComponent } from './offer-letter-documents/offer-letter-documents.component';
import { StudentOverviewComponent } from './student-overview/student-overview.component';
import { VisaDocumentsComponent } from './visa-documents/visa-documents.component';
import { InvoiceComponent } from '../Invoice/invoice/invoice.component';


const routes: Routes = [
{
  path: 'lead/:leadId',
  component: AustraliaServiceMovementComponent,
  children: [
    {
      path:'',
      component:StudentOverviewComponent
    },
    {
      path:'applications/:leadId',
      component:ApplicationsPerUserComponent
    },
    {
      path:'offerDoc/:leadId',
      component: OfferLetterDocumentsComponent
    },
    {
      path:'visaDoc/:leadId',
      component: VisaDocumentsComponent
    },
    {
      path:'comments/:leadId',
      component:CommentsComponent
    },
    {
      path:'fileUpload/:leadId',
      component : DemoFileUploadComponent
    },
    {
      path:'invoice/:leadId',
      component : InvoiceComponent
    }
    ]
  }
]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LeadOffersRoutingModule { }
