<input type="file" class="file-input"
       (change)="onFileSelected($event)" #fileUpload>

<div class="file-upload">

   {{fileName || "No file uploaded yet."}}

    <button class="upload-btn"
      (click)="fileUpload.click()">
        attach_file
    </button>
</div>