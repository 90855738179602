import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CRMLeadServiceService } from 'src/app/Services/leadAPI/crmlead-service.service';

@Component({
  selector: 'app-australia-service-movement',
  templateUrl: './australia-service-movement.component.html',
  styleUrls: ['./australia-service-movement.component.scss']
})
export class AustraliaServiceMovementComponent implements OnInit {

  // Variable to store shortLink from api response
  shortLink: string = "";
  loading: boolean = false; // Flag variable
  file: File = null; // Variable to store file
  name = "Romit"
  id = "12"
  lead : any;
  leadId :any;
  // Inject service 
  constructor(private fileUploadService: CRMLeadServiceService, private _router : Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.leadId = this.route.snapshot.params['leadId']
    this.getsingleLead()
  }

  getsingleLead(){
    this.fileUploadService.getSingleLead(this.leadId).subscribe(
      data => {
        this.lead = data
        console.log("single")
        console.log(data) 
      },
      err => console.log(err.message),
      ()=> console.log("lead Data fetched successfully")
    )
  }

  // On file Select
  onChange(event) {
    this.file = event.target.files[0];
  }
  fileUploadInAngular(event){
    console.log(event)
  }
  // OnClick of button Upload
  onUpload() {
    this.loading = !this.loading;
    console.log(this.file);
    this.fileUploadService.upload(this.file).subscribe(
      (event: any) => {
        if (typeof (event) === 'object') {

          // Short link via api response
          this.shortLink = event.link;

          this.loading = false; // Flag variable 
        }
      }
    );
  }

  routeToStudentOverView(){
    this._router.navigate([`/user/leadOfferService/lead/${this.leadId}`])
  }

  routeToApplications(){
    this._router.navigate([`/user/leadOfferService/lead/${this.leadId}/applications/${this.leadId}`])
  }

  routeToVisaDoc(){
    this._router.navigate([`/user/leadOfferService/lead/${this.leadId}/visaDoc/${this.leadId}`])
  }

  routeToOfferDoc(){
    this._router.navigate([`/user/leadOfferService/lead/${this.leadId}/offerDoc/${this.leadId}`])
  }

  routeToComments(){
    this._router.navigate([`/user/leadOfferService/lead/${this.leadId}/comments/${this.leadId}`])
  }

  routeToInvoice(){
    this._router.navigate([`/user/leadOfferService/lead/${this.leadId}/invoice/${this.leadId}`])
  }
}
