import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AdminService } from "src/app/Services/adminService/admin.service";
import { CRMLeadServiceService } from "src/app/Services/leadAPI/crmlead-service.service";
import { PDFDict, PDFDocument, PDFName, rgb } from "pdf-lib";
import { NgForm } from "@angular/forms";
@Component({
  selector: "app-student-overview",
  templateUrl: "./student-overview.component.html",
  styleUrls: ["./student-overview.component.scss"],
})
export class StudentOverviewComponent implements OnInit {
  viewLoader: boolean = false;
  discount: any;
  netAmount: number = 0;
  grossAmount: number = 0;
  constructor(
    private leadApi: CRMLeadServiceService,
    private route: ActivatedRoute,
    private _adminService: AdminService,
    private toastService: ToastrService
  ) {
    this.leadId = this.route.snapshot.params["leadId"];
    console.log(this.leadId);
    this.getsingleLead();
    this.viewLoader = true;
  }

  totalCharges = 190;

  leadNotes = "";
  leadNotesNew = "";

  viewName = true;
  viewGender = true;
  leadId!: any;
  lead: any;

  editableIndex = "";
  editableValue = "";

  agentDetails = [
    {
      Title: "Mr",
      FamilyName: "KAMALJEET SINGH",
      GivenName: "",
      dob: "",
      orgName: "THE MIIGRATORS OVERSEAS EDUCATION AND IMMIGRATION CONSULTANTS",
      busAddress: {
        str: "UNIT 17/ 22-30, WALLACE AVENUE",
        sub: "POINT COOK",
        cntry: "VICTORIA, AUSTRALIA",
        pc: "3030",
      },
      corrAddress: {
        str: "SAME AS ABOVE",
        sub: "",
        cntry: "",
        pc: "",
      },
      Telephone: {
        cc: "",
        ac: "",
        ph: "",
        mob: "+61461576030",
      },
      commEmail: "kamaljeet.singh@themiigrators.com",
      marn: "2015736",
      signLink: "../../../../../assets/kamal.png",
    },
    {
      Title: "Mr",
      FamilyName: "MAHAJAN",
      GivenName: "SHUBHAM",
      dob: "",
      orgName: "THE MIIGRATORS OVERSEAS EDUCATION AND IMMIGRATION CONSULTANTS",
      busAddress: {
        str: "UNIT 17/ 22-30, WALLACE AVENUE",
        sub: "POINT COOK",
        cntry: "VICTORIA, AUSTRALIA",
        pc: "3030",
      },
      corrAddress: {
        str: "SAME AS ABOVE",
        sub: "",
        cntry: "",
        pc: "",
      },
      Telephone: {
        cc: "",
        ac: "",
        ph: "",
        mob: "+61461576030",
      },
      commEmail: "shubham.mahajan@themiigrators.com",
      marn: "2418438",
      signLink: "../../../../../assets/shubham.png",
    },
    {
      Title: "Mrs",
      FamilyName: "VARINDER KAUR",
      GivenName: "",
      dob: "",
      orgName: "THE MIIGRATORS OVERSEAS EDUCATION AND IMMIGRATION CONSULTANTS",
      busAddress: {
        str: "LEVEL 4, SUITE 402 530 LITTLE COLLINS STREET",
        sub: "MELBOURNE",
        cntry: "VICTORIA, AUSTRALIA",
        pc: "3000",
      },
      corrAddress: {
        str: "SAME AS ABOVE",
        sub: "",
        cntry: "",
        pc: "",
      },
      Telephone: {
        cc: "",
        ac: "",
        ph: "",
        mob: "+61417300003",
      },
      commEmail: "Varinder.kaur@themiigrators.com",
      marn: "2418491",
      signLink: "../../../../../assets/vkaur.png",
    },
  ];

  showAnimation = false;

  paymntReceived = 0;

  ngOnInit(): void {}

  getsingleLead() {
    this.leadApi.getSingleLead(this.leadId).subscribe(
      (data) => {
        this.lead = data;
        this.leadNotes = data.notes.replace(/\n/g, '<br>');
        console.log(data);
      },
      (err) => console.log(err.message),
      () => console.log("lead Data fetched successfully")
    );
  }

  clickMe() {
    alert("Okay mission successfull");
  }

  editName() {
    this.viewName = false;
  }

  saveName() {
    this.viewName = true;
  }

  editGender() {
    this.viewGender = false;
  }

  saveGender() {
    this.viewGender = true;
  }

  commonEdit(editableIndex, editableValue) {
    this.editableIndex = editableIndex;
    this.editableValue = editableValue;
  }

  commonSave(saveId: string, addrId = "0") {
    let obj = {};
    if (saveId == "1") {
      obj = {
        firstName: this.editableValue,
      };
    } else if (saveId == "2") {
      obj = {
        lastName: this.editableValue,
      };
    } else if (saveId == "3") {
      obj = {
        emailId: this.editableValue,
      };
    } else if (saveId == "4") {
      obj = {
        phoneNumber: this.editableValue,
      };
    } else if (saveId == "5") {
      obj = {
        leadAddresses: [
          {
            id: addrId,
            addressLine1: this.editableValue,
          },
        ],
      };
    } else if (saveId == "6") {
      obj = {
        leadAddresses: [
          {
            id: addrId,
            city: this.editableValue,
          },
        ],
      };
    } else if (saveId == "7") {
      obj = {
        leadAddresses: [
          {
            id: addrId,
            state: this.editableValue,
          },
        ],
      };
    } else if (saveId == "8") {
      obj = {
        leadAddresses: [
          {
            id: addrId,
            pincode: this.editableValue,
          },
        ],
      };
    }
    this._adminService.commonUpdateLead(this.leadId, obj).subscribe(
      (data) => {
        this.getsingleLead();
        this.editableValue = "";
        this.editableIndex = "";
      },
      (err) => {
        this.toastService.error("Some error occured please try in some time.");
      }
    );
  }

  leadNotesUpdate() {
    let tempLeadNotes = this.leadNotes + " " + this.leadNotesNew;
    this.leadApi.updateNotes(tempLeadNotes, this.leadId).subscribe((data) => {
      this.leadNotes = tempLeadNotes.replace(/\n/g, '<br>');
      this.leadNotesNew = "";
    });
  }

  async modifyPdf() {
    let i = this.selectedOption;
    if (i == 99) {
      alert("Please select a MARA agent");
    } else {
      this.showAnimation = true;
      try {
        // Load existing PDF file (replace with your PDF file path)
        const url = "../../../../../assets/956_unlocked.pdf"; // Replace with your PDF file path
        const existingPdfBytes = await fetch(url).then((res) =>
          res.arrayBuffer()
        );

        // Load the PDF document
        const pdfDoc = await PDFDocument.load(existingPdfBytes, {
          ignoreEncryption: true,
        });
        const form = pdfDoc.getForm();
        form.getCheckBox("mg.app1").check();
        if (this.agentDetails[i].Title == "Mr") {
          form.getCheckBox("mg.title1").check();
        } else if (this.agentDetails[i].Title == "Mrs") {
          form.getCheckBox("mg.title2").check();
        } else if (this.agentDetails[i].Title == "Miss") {
          form.getCheckBox("mg.title3").check();
        }
        form
          .getTextField("mg.name fam")
          .setText(this.agentDetails[i].FamilyName);
        form
          .getTextField("mg.name giv")
          .setText(this.agentDetails[i].GivenName);
        form.getTextField("mg.dob").setText(this.agentDetails[i].dob);
        form.getTextField("mg.org name").setText(this.agentDetails[i].orgName);
        form
          .getTextField("mg.resadd str")
          .setText(this.agentDetails[i].busAddress.str);
        form
          .getTextField("mg.resadd sub")
          .setText(this.agentDetails[i].busAddress.sub);
        form
          .getTextField("mg.resadd cntry")
          .setText(this.agentDetails[i].busAddress.cntry);
        form
          .getTextField("mg.resadd pc")
          .setText(this.agentDetails[i].busAddress.pc);
        form
          .getTextField("mg.postal str")
          .setText(this.agentDetails[i].corrAddress.str);
        form
          .getTextField("mg.postal sub")
          .setText(this.agentDetails[i].corrAddress.sub);
        form
          .getTextField("mg.postal cntry")
          .setText(this.agentDetails[i].corrAddress.cntry);
        form
          .getTextField("mg.postal pc")
          .setText(this.agentDetails[i].corrAddress.pc);
        form
          .getTextField("mg.off ph cc")
          .setText(this.agentDetails[i].Telephone.cc);
        form
          .getTextField("mg.off ph ac")
          .setText(this.agentDetails[i].Telephone.ac);
        form
          .getTextField("mg.off ph")
          .setText(this.agentDetails[i].Telephone.ph);
        form.getTextField("mg.mob").setText(this.agentDetails[i].Telephone.mob);
        form.getTextField("mg.email").setText(this.agentDetails[i].commEmail);
        form.getTextField("mg.marn").setText(this.agentDetails[i].marn);
        form.getCheckBox("mg.comm2").check();
        form.getCheckBox("mg.prov assist1").check();
        form.getCheckBox("mg.oth mig1").check();
        form.getCheckBox("cc.person rec1").check();
        form.getCheckBox("ar.also2").check();
        form.getCheckBox("mg.dec 1").check();
        form.getCheckBox("cc.dec 1").check();

        //client details
        form.getTextField("cc.name fam").setText(this.lead?.lastName);
        form.getTextField("cc.name giv").setText(this.lead?.firstName);
        form.getTextField("cc.dob").setText(this.formatDate(this.lead?.dob));
        form
          .getTextField("cc.resadd str")
          .setText(this.lead?.leadAddresses[0]?.addressLine1);
        form
          .getTextField("cc.resadd sub")
          .setText(this.lead?.leadAddresses[0]?.city);
        form
          .getTextField("cc.resadd cntry")
          .setText(
            this.lead?.leadAddresses[0]?.state +
              ", " +
              this.lead?.leadAddresses[0]?.country
          );
        form
          .getTextField("cc.resadd pc")
          .setText((this.lead?.leadAddresses[0]?.pincode).toString());
        form.getTextField("cc.mob").setText(this.lead?.phoneNumber);

        form.getCheckBox("ta.assist1").check();
        form
          .getTextField("ta.type")
          .setText(
            (this.lead?.leadToService[0]?.Service.serviceName).toUpperCase()
          );
        form.getCheckBox("ta.not yet").check();
        form.getCheckBox("ar.also2").check();

        form.getTextField("mg.dec date").setText(this.getFormattedDate());
        form.getTextField("cc.dec date").setText(this.getFormattedDate());

        const signatureImageBytes = await this.loadFile(
          this.agentDetails[i].signLink
        );
        const signatureImage = await pdfDoc.embedPng(signatureImageBytes);

        // Get the first page of the PDF (adjust index as needed)
        const firstPage = pdfDoc.getPages()[5];

        // Add the image to the first page
        let height = 35;
        let width = 150;
        // Adjust scaling as needed
        firstPage.drawImage(signatureImage, {
          x: 120, // Adjust position as needed
          y: 443,
          width,
          height,
        });

        // Save the modified PDF
        const modifiedPdfBytes = await pdfDoc.save();

        // Create a Blob object to create a downloadable link
        const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });
        const urlBlob = window.URL.createObjectURL(blob);

        // Create a link element and trigger a download
        const link = document.createElement("a");
        link.href = urlBlob;
        link.download = "956_"+this.lead.firstName+".pdf";
        link.click();

        console.log("PDF modified and saved successfully!");
      } catch (error) {
        console.error("Error modifying PDF:", error);
      } finally {
        this.showAnimation = false;
      }
    }
  }

  async loadFile(filePath: string): Promise<ArrayBuffer> {
    try {
      const response = await fetch(filePath);
      if (!response.ok) {
        throw new Error("Failed to load file");
      }
      return await response.arrayBuffer();
    } catch (error) {
      console.error(`Error loading file '${filePath}':`, error);
      throw error;
    }
  }

  getFormattedDate(): string {
    let today = new Date();
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1; // January is 0!
    const yyyy = today.getFullYear();

    // Add leading zeroes to single-digit month and date
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    return dd + "/" + mm + "/" + yyyy;
  }

  // Function to convert yyyy-mm-dd to dd-mm-yyyy format
  formatDate(originalDate: string): string {
    // Split the original date string into parts based on '-'
    const parts = originalDate.split("-");

    // Rearrange the parts into dd-mm-yyyy format
    const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

    return formattedDate;
  }

  selectedOption = 99;
  selectOption(event: any) {
    this.selectedOption = event.target.value;
    console.log(this.selectedOption)
  }

  secSelectedOption = 99;
  secSelectOption(event: any) {
    this.secSelectedOption = event.target.value;
    console.log(this.secSelectedOption)
  }
  
  gstOptionValue = "99";
  gstOption(event: any) {
    this.gstOptionValue = event.target.value;
  }

  currValue = "99";
  currOption(event: any) {
    this.currValue = event.target.value;
  }

  addDiscount(event){
    this.discount = event.target.value;
    this.netAmount = this.grossAmount - (event.target.value/ 100) * this.grossAmount;
  }
  
  getTotalCharges(event:any){
    this.totalCharges =  this.grossAmount + this.netAmount 
    console.log(this.totalCharges)
  }
  
  submitForm(entryForm: NgForm){
    console.log(entryForm.value.agentFees)
    this.modifyClientAgreementPdf();
  }

  async modifyClientAgreementPdf() {
    let i = this.secSelectedOption;
    if(this.currValue == "99"){
      alert("Please select the currency value")
    }
    else if(this.gstOptionValue == "99"){
      alert("Please select GST option")
    }
    else if (i == 99) {
      alert("Please select a MARA agent");
    } else {
      this.showAnimation = true;
      let gst
      if(this.gstOptionValue == "true"){
         gst = 0.10
      }
      else{
        gst = 0
      }
      try {
        // Load existing PDF file (replace with your PDF file path)
        const url = "../../../../../assets/Client agreement.pdf"; // Replace with your PDF file path
        const existingPdfBytes = await fetch(url).then((res) =>
          res.arrayBuffer()
        );

        // Load the PDF document
        const pdfDoc = await PDFDocument.load(existingPdfBytes, {
          ignoreEncryption: true,
        });
        const form = pdfDoc.getForm();
        
        form.getTextField('Migration Agent Name').setText(this.agentDetails[i].GivenName + " " + this.agentDetails[i].FamilyName)
        form.getTextField('Agent MARN').setText(this.agentDetails[i].marn);
        form.getTextField('Agent Business Address').setText(this.agentDetails[i].busAddress.str + ", " + this.agentDetails[i].busAddress.sub + ", " + this.agentDetails[i].busAddress.cntry + ", " + this.agentDetails[i].busAddress.pc);
        form.getTextField('Agent Email Address').setText(this.agentDetails[i].commEmail);
        form.getTextField('Agent Contact Number').setText(this.agentDetails[i].Telephone.mob);
        form.getTextField('Agent Landline Number').setText("");
        form.getTextField('Current Date').setText(this.getFormattedDate());
        form.getTextField('Client Name').setText(this.lead.firstName + " " + this.lead.lastName);
        form.getTextField('Client Address').setText(this.lead?.leadAddresses[0]?.addressLine1 + ", " + this.lead?.leadAddresses[0]?.city+ ", " + this.lead?.leadAddresses[0]?.state + ", " + this.lead?.leadAddresses[0]?.country + ", "  + this.lead?.leadAddresses[0]?.pincode);
        form.getTextField('Client Date of Birth').setText(this.formatDate(this.lead?.dob));
        form.getTextField('Client Gender').setText("");
        form.getTextField('Client Email Address_2').setText(this.lead?.emailId);
        form.getTextField('Client Contact Number_2').setText(this.lead?.phoneNumber);
        console.log(this.lead?.leadToService[0]?.Service.serviceName)
        form.getTextField('SERVICE').setText(this.lead?.leadToService[0]?.Service.serviceName);
        form.getTextField('Current Date 1').setText(this.getFormattedDate());
        form.getTextField('TOTAL PAYMENT').setText(this.currValue +" " +(this.totalCharges * gst + this.totalCharges).toString());
        form.getTextField('AGENT FEES').setText(this.currValue +" " +this.netAmount.toString());
        form.getTextField('Application Charges').setText(this.currValue +" " +this.grossAmount.toString());
        form.getTextField('TOTAL FEES').setText(this.currValue +" " +((this.totalCharges * gst + this.totalCharges).toString()));
        form.getTextField('SERVICE 1').setText(this.lead?.leadToService[0]?.Service.serviceName);
        form.getTextField('PRICE').setText(this.currValue +" " +this.totalCharges.toString());
        form.getTextField('GST 23').setText(this.currValue +" " +(this.totalCharges * gst).toString());
        form.getTextField('TOTAL CHARGES').setText(this.currValue +" " +(this.totalCharges * gst + this.totalCharges).toString());
        form.getTextField('PAYMENT RECEIVED').setText(this.currValue +" " +this.paymntReceived.toString());
        form.getTextField('Current Date 2').setText(this.getFormattedDate());
        form.getTextField('ADDRESS 3').setText(this.lead?.leadAddresses[0]?.city+ ", " + this.lead?.leadAddresses[0]?.state + ", " + this.lead?.leadAddresses[0]?.country + ", "  + this.lead?.leadAddresses[0]?.pincode);
        form.getTextField('CLIENT NAME 44').setText(this.lead.firstName + " " + this.lead.lastName);
        form.getTextField('SIGNATURE OF THE CLIENT').setText(this.lead.firstName + " " + this.lead.lastName);

        const signatureImageBytes = await this.loadFile(
          this.agentDetails[i].signLink
        );
        const signatureImage = await pdfDoc.embedPng(signatureImageBytes);

        // Get the first page of the PDF (adjust index as needed)
        const firstPage = pdfDoc.getPages();

        firstPage.forEach(element => {
          // Add the image to the first page
          let height = 35;
          let width = 150;
          // Adjust scaling as needed
          element.drawImage(signatureImage, {
            x: 438, // Adjust position as needed
            y: 30,
            width,
            height,
          });
        });

        // Save the modified PDF
        const modifiedPdfBytes = await pdfDoc.save();

        // Create a Blob object to create a downloadable link
        const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });
        const urlBlob = window.URL.createObjectURL(blob);

        // Create a link element and trigger a download
        const link = document.createElement("a");
        link.href = urlBlob;
        link.download = "ClientAgreement_"+this.lead.firstName+".pdf";
        link.click();

        console.log("PDF modified and saved successfully!");
      } catch (error) {
        console.error("Error modifying PDF:", error);
      } finally {
        this.showAnimation = false;
      }
    }
  }

}
