import { Component, Input, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CRMLeadServiceService } from "src/app/Services/leadAPI/crmlead-service.service";

@Component({
  selector: "app-add-education",
  templateUrl: "./add-education.component.html",
  styleUrls: ["./add-education.component.scss"],
})
export class AddEducationComponent implements OnInit {
  @Input() leadId = "";
  //@Input() abcdefgh : ()=>{};

  viewLoader: boolean = true;
  file: File;
  constructor(private leadApi: CRMLeadServiceService, private router: Router, private toastService: ToastrService) {}

  ngOnInit(): void {}

  onChange(event) {
    this.file = event.target.files[0];
  }

  addEducationDetails(form: NgForm) {
    this.viewLoader = false;
    console.log(form.value);
    const formData = new FormData();

    formData.append("file", this.file);
    formData.append("fieldId", form.value.field);
    formData.append("levelOfEducation", form.value.levelEdu);
    formData.append("collegeId", form.value.schoolEdu);
    // formData.append("startDate", form.value.startDate)
    // formData.append("completionDate", form.value.endDate)
    formData.append("startDate", form.value.startDate);
    formData.append("completionDate", form.value.endDate);
    formData.append("marks", form.value.marks);
    formData.append("leadId", this.leadId);
    formData.append("isCompletedProgram", "true");

    console.log(formData);
    this.leadApi.postEducation(formData).subscribe(
      (data) => {
        console.log(data);
        this.toastService.success("Education document uploaded successfully");

        this.viewLoader = true;
        document.getElementById("closeButton").click();
      },
      (err) => {
        this.viewLoader = true;
        console.log(err);
      },
      () => {
        console.log("education ducument added successfully.");
      }
    );
  }
}
