import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CRMLeadServiceService } from 'src/app/Services/leadAPI/crmlead-service.service';

@Component({
  selector: 'app-search-course-for-lead',
  templateUrl: './search-course-for-lead.component.html',
  styleUrls: ['./search-course-for-lead.component.scss']
})
export class SearchCourseForLeadComponent implements OnInit {

  @Input() leadId ="";

  viewLoader2 : boolean = true;

  Colleges:any = [
    // {
    //   collegeName : "Norquest college",
    //   Address : "Alberta, Edmonton",
    //   courseName:"Hospitality",
    //   level:"Diploma",
    //   fee:"100",
    //   duration:"2years",
    //   Intakes :{
    //     January : false,
    //     May : true,
    //     September : false
    //   }
    // }
  ]
  constructor(private leadApi : CRMLeadServiceService, private router:Router) { 
  }
  
  ngOnInit(): void {
    console.log(this.leadId)
    this.getCourses()
  }


  getCourses(){
    this.viewLoader2 = false;
    this.leadApi.getCourse(3, 0).subscribe(
      data =>{
        this.Colleges = data
        console.log(data)
        this.viewLoader2 = true;
      },
      err =>{
        this.viewLoader2 = true;
      },
      ()=>{
        
      }
    )
  }

  applyForIntake(intakeChoosen){
    this.viewLoader2 = false;
    let z = confirm("You are applying for a course, This is the final step and will not be reverted.");
    if(z){

      this.leadApi.postLeadApplicationToCourse(this.leadId,intakeChoosen).subscribe(
        data => {
        console.log("Successfully Applied")
        console.log(data)
        this.viewLoader2 = true;
        document.getElementById("getApplications").click();
      },
      err =>{
        alert("Error occured, Please try after some time.")
        this.viewLoader2 = true;
      },
      () =>{
        console.log("apply try")
      }
      )
    }
    else{
      this.viewLoader2 = true;
    }
  }

}
