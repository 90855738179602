<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Document</title>
  </head>
  <body>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <button
              class="btn btn-outline-danger"
              data-toggle="modal"
              data-target="#exampleModalLong"
              data-backdrop="static"
              data-keyboard="false"
              (click)="setPassportView()"
            >
              Add Passport
            </button>
            <button
              class="btn btn-outline-danger"
              data-toggle="modal"
              data-target="#exampleModalLong"
              data-backdrop="static"
              data-keyboard="false"
              (click)="setEducationView()"
            >
              Add Education
            </button>
            <button
            class="btn btn-outline-danger"
            data-toggle="modal"
            data-target="#exampleModalLong"
            data-backdrop="static"
            data-keyboard="false"
            (click)="setIeltsPteView()"
          >
            Add IELTS/ PTE
          </button>
          <button
              class="btn btn-outline-danger"
              data-toggle="modal"
              data-target="#exampleModalLong"
              data-backdrop="static"
              data-keyboard="false"
              (click)="setStudyGapView()"
            >
              Add Study Gap
            </button>
            <button
              class="btn btn-outline-danger"
              data-toggle="modal"
              data-target="#exampleModalLong"
              data-backdrop="static"
              data-keyboard="false"
              (click)="setEmploymentView()"
            >
              Add Employment
            </button>
        </div>
        <div class="col-10">
          <table border="1" class="table table-hover table-primary" *ngIf="addPassportView">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Passport No.</th>
                <th scope="col">Name</th>
                <th scope="col">Issue date</th>
                <th scope="col">Expiry date</th>
                <th scope="col">Download</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let passport of passports; let i = index">
                <th scope="row">{{ i + 1 }}</th>
                <td>{{ passport.passportId }}</td>
                <td>{{ passport.name }}</td>
                <td>{{ passport.issueDate | date : "shortDate" }}</td>
                <td>{{ passport.expiryDate | date : "shortDate" }}</td>
                <td>
                  <a
                    href="{{ apiLink }}document?id={{ passport.documentId }}"
                    target="_blank"
                    ><i class="fas fa-cloud-download-alt mr-2"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
          <table border="1" class="table table-hover table-primary" *ngIf="addEducationView">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Level of education</th>
                <th scope="col">School/ College</th>
                <th scope="col">Field/ Stream</th>
                <th scope="col">Start Date</th>
                <th scope="col">End Date</th>
                <th scope="col">Marks</th>
                <th scope="col">Download</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let acad of academics; let j = index">
                <th scope="row">{{ j + 1 }}</th>
                <td>{{ acad.levelOfEducation }}</td>
                <td>{{ acad.collegeId }}</td>
                <td>{{ acad.fieldId }}</td>
                <td>{{ acad.startDate | date : "shortDate" }}</td>
                <td>{{ acad.completionDate | date : "shortDate" }}</td>
                <td>{{ acad.marks }}</td>
                <td>
                  <a
                    href="{{ apiLink }}document?id={{ acad.documentId }}"
                    target="_blank"
                    ><i class="fas fa-cloud-download-alt mr-2"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
          <table border="1" class="table table-hover table-primary" *ngIf="addIeltsPTEView">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Test Type</th>
                <th scope="col">Proficiency Test Type</th>
                <th scope="col">Completion Date</th>
                <th scope="col">Listening</th>
                <th scope="col">Speaking</th>
                <th scope="col">Reading</th>
                <th scope="col">Writing</th>
                <th scope="col">Overall</th>
                <th scope="col">Download</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let iel of ielts; let k = index">
                <th scope="row">{{ k + 1 }}</th>
                <td>{{ iel.englishLanguageMeetingRequirement }}</td>
                <td>{{ iel.englishProficiencyTestType }}</td>
                <td>{{ iel.testCompletionDate | date : "shortDate" }}</td>
                <td>{{ iel.listening }}</td>
                <td>{{ iel.speaking }}</td>
                <td>{{ iel.reading }}</td>
                <td>{{ iel.writing }}</td>
                <td>{{ iel.overallBand }}</td>
                <td>
                  <a
                    href="{{ apiLink }}document?id={{ iel.documentId }}"
                    target="_blank"
                    ><i class="fas fa-cloud-download-alt mr-2"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
          <table border="1" class="table table-hover table-primary" *ngIf="addStudyGapView">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Gap Reason</th>
                <th scope="col">Start Date</th>
                <th scope="col">End Date</th>
                <th scope="col">Download</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let gap of studyGap; let l = index">
                <th scope="row">{{ l + 1 }}</th>
                <td>{{ gap.gapReason }}</td>
                <td>{{ gap.gapStartDate | date : "shortDate" }}</td>
                <td>{{ gap.gapEndDate | date : "shortDate" }}</td>
                <td>
                  <a
                    href="{{ apiLink }}document?id={{ gap.documentId }}"
                    target="_blank"
                    ><i class="fas fa-cloud-download-alt mr-2"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
          <table border="1" class="table table-hover table-primary" *ngIf="addEmploymentView">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Employment Type</th>
                <th scope="col">Job Title</th>
                <th scope="col">Start Date</th>
                <th scope="col">End Date</th>
                <th scope="col">Still Working Here?</th>
                <th scope="col">Relevant Work experience?</th>
                <th scope="col">Download</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let emp of employs; let m = index">
                <th scope="row">{{ m + 1 }}</th>
                <td>{{ emp.employmentType }}</td>
                <td>{{ emp.jobTitle }}</td>
                <td>{{ emp.startDate | date : "shortDate" }}</td>
                <td>{{ emp.endDate | date : "shortDate" }}</td>
                <td>{{ emp.isStillWorkingHere }}</td>
                <td>{{ emp.isReleventExperience }}</td>
                <td>
                  <a
                    href="{{ apiLink }}document?id={{ emp.documentId }}"
                    target="_blank"
                    ><i class="fas fa-cloud-download-alt mr-2"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </body>
</html>
<div
  class="modal fade"
  id="exampleModalLong"
  style="padding-right: 5px"
  data-modal-width="99vw"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLongTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl modal-side modal-top-right" role="document">
    <div id="print-section" class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Offer Documents</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          id="closeButton"
          (click)="ngOnInit()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form #EntryForm="ngForm" autoComplete="off">
        <div class="modal-body">
          <div class="container-fluid">
            <div class="card">
              <div *ngIf="addPassportView" class="card-header">
                <h6 class="m-0 text-dark">Passport Details</h6>
              </div>
              <div *ngIf="addStudyGapView" class="card-header">
                <h6 class="m-0 text-dark">Study Gap/ Employment</h6>
              </div>
              <div *ngIf="addIeltsPTEView" class="card-header">
                <h6 class="m-0 text-dark">IELTS/ PTE</h6>
              </div>
              <div *ngIf="addEducationView" class="card-header">
                <h6 class="m-0 text-dark">Education Details</h6>
              </div>
              <div *ngIf="addEmploymentView" class="card-header">
                <h6 class="m-0 text-dark">Employment Details</h6>
              </div>
              <div *ngIf="addIeltsPTEView" class="card-body">
                <app-ielts-pte-docs [leadId]="leadId"></app-ielts-pte-docs>
              </div>
              <div *ngIf="addStudyGapView" class="card-body">
                <app-study-gap-employment
                  [leadId]="leadId"
                ></app-study-gap-employment>
              </div>
              <div *ngIf="addPassportView" class="card-body">
                <app-addpassport [leadId]="leadId"></app-addpassport>
              </div>
              <div *ngIf="addEducationView" class="card-body">
                <app-add-education [leadId]="leadId"></app-add-education>
              </div>
              <div *ngIf="addEmploymentView" class="card-body">
                <app-addemployment [leadId]="leadId"></app-addemployment>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
