import { Component, OnInit, Input } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { CRMLeadServiceService } from "src/app/Services/leadAPI/crmlead-service.service";

@Component({
  selector: "app-ielts-pte-docs",
  templateUrl: "./ielts-pte-docs.component.html",
  styleUrls: ["./ielts-pte-docs.component.scss"],
})
export class IeltsPteDocsComponent implements OnInit {
  @Input() leadId = "";

  viewLoader: boolean = true;
  constructor(private leadApi: CRMLeadServiceService,
    private toastService: ToastrService) {}

  file: File;

  ngOnInit(): void {}

  onChange(event) {
    this.file = event.target.files[0];
  }

  addIeltsDetails(form: NgForm) {
    this.viewLoader = false;
    const formData = new FormData();

    formData.append("file", this.file);
    formData.append(
      "englishLanguageMeetingRequirement",
      form.value.meetingEnglish
    );
    formData.append("englishProficiencyTestType", form.value.proficiency);
    formData.append("testCompletionDate", form.value.completionDate);
    formData.append("overallBand", form.value.overallBand);
    formData.append("listening", form.value.listening);
    formData.append("reading", form.value.reading);
    formData.append("speaking", form.value.speaking);
    formData.append("writing", form.value.writing);
    formData.append("leadId", this.leadId);

    this.leadApi.createIeltsPte(formData).subscribe(
      (data) => {
        this.toastService.success("Ielts/PTE document uploaded successfully");
        this.viewLoader = true;
        document.getElementById("closeButton").click();
      },
      (err) => {
        console.log(err);
        this.viewLoader = true;
      },
      () => console.log("passport add successfully executed.")
    );
  }
}
