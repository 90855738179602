import { Component, OnInit, Input } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { CRMLeadServiceService } from "src/app/Services/leadAPI/crmlead-service.service";

@Component({
  selector: "app-addemployment",
  templateUrl: "./addemployment.component.html",
  styleUrls: ["./addemployment.component.scss"],
})
export class AddemploymentComponent implements OnInit {
  @Input() leadId: "";
  file: File;

  viewLoader:boolean= true;
  constructor(private leadApi: CRMLeadServiceService, private toastService: ToastrService) {}

  ngOnInit(): void {}

  onChange(event) {
    this.file = event.target.files[0];
  }

  addEmployment(
    form: NgForm,
  ) {
    this.viewLoader = false;
    const formData = new FormData();

    formData.append("file", this.file);
    formData.append("isReleventExperience", form.value.isRelevant);
    formData.append("startDate", form.value.jobStart);
    formData.append("endDate", form.value.jobEnd);
    formData.append("employmentType", form.value.empType);
    formData.append("jobTitle", form.value.title);
    formData.append("isStillWorkingHere", form.value.stillWor);
    formData.append("leadId", this.leadId);

    this.leadApi.postEmploment(formData).subscribe((data) => {
      console.log(data);
      this.toastService.success("Employment document uploaded successfully");

      document.getElementById("closeButton").click();
      this.viewLoader = true;
    },
    err=>{
      console.log(err)
      this.viewLoader = true;
    },
    ()=>{
        
    } 
    );
  }
}
