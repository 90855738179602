import { Component, OnInit, Input } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CRMLeadServiceService } from "src/app/Services/leadAPI/crmlead-service.service";

@Component({
  selector: "app-sponsor-affidavit",
  templateUrl: "./sponsor-affidavit.component.html",
  styleUrls: ["./sponsor-affidavit.component.scss"],
})
export class SponsorAffidavitComponent implements OnInit {
  @Input() leadId = "";
  constructor(private leadApi: CRMLeadServiceService, private router : Router, private toastService: ToastrService) {}

  file : File;
  viewLoader : boolean = true;

  ngOnInit(): void {}

  onChange(event) {
    this.file = event.target.files[0];
  }

  addSponsorAffidavit(form: NgForm) {
    this.viewLoader = false;
    console.log(form.value);
    const formData = new FormData();

    formData.append("file", this.file);
    formData.append("sponserName", form.value.sponsorName);
    formData.append("documentType", form.value.docType);
    formData.append("leadId", this.leadId);

    console.log(formData);
    this.leadApi.postSponsorData(formData).subscribe(
      (data) => {
        this.toastService.success("Sponsor document uploaded successfully");
        this.viewLoader = true;
        form.reset();
        this.file = undefined;
      },
      (err) => {
        console.log(err)
        this.viewLoader = true;
      },
      ()=>{
          document.getElementById("closeButton").click();
      }
    )
  }
}
