<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Document</title>
  </head>
  <body>
    <div class="container">
      <div class="card">
        <div class="card-body">
          <form #studyGap="ngForm" (ngSubmit)="addEmployment(studyGap)">
            <div class="row">
              <div class="col-2"></div>
              <div class="col-3">
                <label for="isRelevant">Do you have relevant work experience?</label>
                <br/>
                <select class="form-select mb-3" name="isRelevant" ngModel>
                  <option value=1>Yes</option>
                  <option value=0>No</option>
                </select>
              </div>
              <div class="col-2"></div>
              <div class="col-3">
                <label for="empType">Employment Type</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    name="empType"
                    ngModel
                  />
                </div>
              </div>
            </div>
            <div class="row">
                <div class="col-2"></div>
                <div class="col-3">
                  <label for="title">Job Title</label>
                  <br/>
                  <input
                      type="text"
                      class="form-control"
                      aria-label="Sizing example input"
                      aria-describedby="inputGroup-sizing-default"
                      name="title"
                      ngModel
                    />
                </div>
                <div class="col-2"></div>
                <div class="col-3">
                  <label for="stillWor">Still working here</label>
                  <div class="input-group mb-3">
                    <select class="form-select mb-3" name="stillWor" ngModel>
                        <option value=1>Yes</option>
                        <option value=0>No</option>
                      </select>
                  </div>
                </div>
              </div>
            <div class="row">
              <div class="col-2"></div>
              <div class="col-3">
                <label for="jobStart">Start Date</label>
                <div class="input-group mb-3">
                  <input
                    type="date"
                    class="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    name="jobStart"
                    ngModel
                  />
                </div>
              </div>
              <div class="col-2"></div>
              <div class="col-3">
                <label for="JobEnd">End Date</label>
                <div class="input-group mb-3">
                  <input
                    type="date"
                    class="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    name = "jobEnd"
                    ngModel
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-4"></div>
              <div class="col-4">
                <label for="file">Employment Document</label>
                <div class="input-group mb-3">
                  <input
                  type="file"
                  name="file"
                  class="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-default"
                  (change)="onChange($event)"
                  ngModel
                  />
                </div>
              </div>
              <div class="col-4"></div>
            </div>
            <div class="row">
            <button [disabled]="!viewLoader" type="submit"  class="mx-auto btn btn-primary">Save</button>
          </div>
          </form>
        </div>
      </div>
    </div>
    <!-- <div [hidden]="viewLoader" class="spinner-border positionSpinner"></div> -->
  </body>
</html>
