<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Document</title>
  </head>
  <body>
    <div class="container">
      <div class="card">
        <div class="card-body">
          <form #sponsorAffidavit="ngForm" (ngSubmit)="addSponsorAffidavit(sponsorAffidavit)">
            <div class="row">
              <div class="mx-auto">
                <label for="overallBand">Sponsor Name</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    name="sponsorName"
                    ngModel
                  />
                </div>
              </div>
              <div class="mx-auto">
                <label for="overallBand">Document Type</label>
                <div class="input-group mb-3">
                  <select name="docType" ngModel>
                    <option value="Affidavit">Affidavit</option>
                    <option value="Id">Id</option>
                    <option value="Property">Property</option>
                    <option value="Income">Income</option>
                  </select>
                </div>
              </div>
              <div class="mx-auto">
                <label for="ieltsPteFile">Affidavit</label>
                <div class="input-group mb-3">
                  <input
                    type="file"
                    name="affidavitFile"
                    class="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    (change)="onChange($event)"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <button [disabled]="!viewLoader" class="mx-auto btn btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- <div [hidden]="viewLoader" class="spinner-border positionSpinner"></div> -->
  </body>
</html>
