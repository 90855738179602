<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>CourseList</title>
  </head>

  <body>
    <div class="container-fluid px-4 pt-4 rounded">
      <div class="sticky-top">
        <div class="card shadow" style="border-radius: 10px">
          <div class="card-header border-0">
            <div class="row">
              <div class="col-auto mx-auto">
                <select
                  name="countrySelect"
                  class="form-control form-control-sm"
                >
                  <option value="Select" selected>Country</option>
                  <option value="Canada">Canada</option>
                  <option value="Australia">Australia</option>
                  <option value="NewZealand">New Zealand</option>
                  <option value="UK">United Kingdom</option>
                </select>
              </div>
              <div class="col-auto mx-auto">
                <select
                  name="State Province or Territory"
                  class="form-control form-control-sm"
                >
                  <option value="default" selected disabled>
                    State Province or Territory
                  </option>
                  <option value="Alberta, Edmonton">Alberta, Edmonton</option>
                </select>
              </div>
              <div class="col-auto mx-auto">
                <select
                  name="State Province or Territory"
                  class="form-control form-control-sm"
                >
                  <option value="default" selected>
                    Field of Study / Sub-Field
                  </option>
                  <option value="Alberta, Edmonton">Alberta, Edmonton</option>
                </select>
              </div>
              <div class="col-auto mx-auto">
                <select
                  name="State Province or Territory"
                  class="form-control form-control-sm"
                >
                  <option value="default" selected>Level of Education</option>
                  <option value="Alberta, Edmonton">Alberta, Edmonton</option>
                </select>
              </div>
              <div class="col-auto mx-auto">
                <select
                  name="Field of study"
                  class="form-control form-control-sm"
                >
                  <option value="default">Intakes - 2023</option>
                  <option value="Arts">January</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="card mx-auto clgCard" *ngFor="let col of Colleges">
          <div class="card-header">
            <div class="row">
              <div class="col-auto mx-auto">
                <img src="../../../../../assets/img/pp.jpg" class="clgImage" />
              </div>
            </div>
            <div class="row">
              <div class="col-auto mx-auto">
                <h5 class="colgName">{{ col?.college?.name }}</h5>
              </div>
            </div>
            <div class="row">
              <div class="col-auto mx-auto">
                <p>
                  {{ col?.college?.collegeState?.stateName
                  }}{{ col?.college?.collegeCountry?.countryName }}
                </p>
              </div>
            </div>
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td>Offer Fees:</td>
                  <td>{{ col?.offerFee | currency : "USD" }}</td>
                </tr>
                <tr>
                  <td>Level Of Education:</td>
                  <td>{{ col?.levelOfEducation?.levelOfEducation }}</td>
                </tr>
                <tr>
                  <td>Course Name:</td>
                  <td>{{ col?.courseName }}</td>
                </tr>
                <tr>
                  <td>Duration:</td>
                  <td>{{ col?.courseDuration }}</td>
                </tr>
              </tbody>
            </table>
          
              <div class="row">
                <p class="mx-auto">Intakes - 2023</p>
              </div>
              <div class="row">
                <div class="col-auto mx-auto">
                  <p class="intakesP">
                    <span
                      ngClass="intakeB"
                      *ngFor="let optio of col.courseToCourseIntake; let i = index"
                      >{{ optio.courseIntake.intakeMonth }} <span *ngIf="col.courseToCourseIntake.length-1 != i">| </span>
                    </span>
                  </p>
                </div>
              </div>
         
            <div class="row">
              <div class="col-auto mx-auto">
                <div class="col-auto my-1">
                  <select
                    class="custom-select mr-sm-2"
                    name="intakeChoosen"
                    #intakeChoosen
                    (change)="applyForIntake(intakeChoosen.value)"
                  >
                    <option selected>Choose Intake...</option>
                    <option
                      value="{{ optio.id }}"
                      *ngFor="let optio of col.courseToCourseIntake"
                    >
                      {{ optio.courseIntake.intakeMonth }}-{{
                        optio.courseIntake.intakeYear
                      }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div [hidden]="viewLoader2" class="spinner-border positionSpinner"></div> -->
  </body>
</html>
