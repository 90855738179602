import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-demo-file-upload',
  templateUrl: './demo-file-upload.component.html',
  styleUrls: ['./demo-file-upload.component.scss']
})
export class DemoFileUploadComponent implements OnInit {

  //constructor() { }

  ngOnInit(): void {
  }

  
  fileName = '';

  constructor(private http: HttpClient) {}

  onFileSelected(event) {

      const file:File = event.target.files[0];

      if (file) {

          //this.fileName = file.name;

          const formData = new FormData();

          formData.append("file", file);

          const upload$ = this.http.post("http://ec2-65-0-95-133.ap-south-1.compute.amazonaws.com:3001/document/studyGap", formData);

          upload$.subscribe();
      }
  }
}
