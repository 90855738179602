import { Component, Input, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { EvenEmitForRefreshService } from "src/app/Services/EventEmitForRefresh/even-emit-for-refresh.service";
import { CRMLeadServiceService } from "src/app/Services/leadAPI/crmlead-service.service";
import { OfferLetterDocumentsComponent } from "../../offer-letter-documents/offer-letter-documents.component";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-study-gap-employment",
  templateUrl: "./study-gap-employment.component.html",
  styleUrls: ["./study-gap-employment.component.scss"],
})
export class StudyGapEmploymentComponent implements OnInit {
  file: File;
  studyGap: any;

  @Input() leadId = "";

  viewLoader: boolean = true;
  constructor(
    private leadApi: CRMLeadServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private eventEmit: EvenEmitForRefreshService,
    private toastService: ToastrService
  ) {}

  ngOnInit(): void {}

  //Add study gap working code
  onChange(event) {
    this.file = event.target.files[0];
  }

  addStudyGap(
    form: NgForm,
    settings: any = { toast: true, hideLoader: false }
  ) {
    this.viewLoader = false;
    const formData = new FormData();

    formData.append("file", this.file);
    formData.append("isStudyGap", form.value.isStudyGap);
    formData.append("gapReason", form.value.gapReason);
    formData.append("gapStartDate", form.value.gapStartDate);
    formData.append("gapEndDate", form.value.gapEndDate);
    formData.append("leadId", this.leadId);

    this.leadApi.postStudyGap(formData).subscribe(
      (data) => {
        console.log(data),
          this.toastService.success("Study gap document uploaded successfully");
        this.viewLoader = true;
      },
      (err) => {
        this.viewLoader = true;
        console.log(err);
      },
      () => {
        document.getElementById("closeButton").click();
        //this.refresh();
        // this.eventEmit.onFirstComponentButtonClick();
      }
    );
  }
}
