import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { LeadOffersRoutingModule } from "./lead-offers-routing.module";

import { LeadRouteHandlerComponent } from "./lead-route-handler/lead-route-handler.component";
import { AustraliaServiceMovementComponent } from "./australia-service-movement/australia-service-movement.component";
import { StudentOverviewComponent } from "./student-overview/student-overview.component";
import { ApplicationsPerUserComponent } from "./applications-per-user/applications-per-user.component";
import { OfferLetterDocumentsComponent } from "./offer-letter-documents/offer-letter-documents.component";
import { VisaDocumentsComponent } from "./visa-documents/visa-documents.component";
import { FormsModule } from "@angular/forms";
import { StudyGapEmploymentComponent } from "./LeadOfferunits/study-gap-employment/study-gap-employment.component";
import { IeltsPteDocsComponent } from "./LeadOfferunits/ielts-pte-docs/ielts-pte-docs.component";
import { AddpassportComponent } from "./LeadOfferunits/addpassport/addpassport.component";
import { AddEducationComponent } from "./LeadOfferunits/add-education/add-education.component";
import { CommentsComponent } from "./LeadOfferunits/comments/comments.component";
import { DemoFileUploadComponent } from "./LeadOfferunits/demo-file-upload/demo-file-upload.component";
import { SponsorAffidavitComponent } from "./LeadOfferunits/sponsor-affidavit/sponsor-affidavit.component";
import { SponsorIdComponent } from "./LeadOfferunits/sponsor-id/sponsor-id.component";
import { SponsorIncomeProofComponent } from "./LeadOfferunits/sponsor-income-proof/sponsor-income-proof.component";
import { SponsorPropertyDocumentsComponent } from "./LeadOfferunits/sponsor-property-documents/sponsor-property-documents.component";
import { AddemploymentComponent } from "./LeadOfferunits/addemployment/addemployment.component";
import { SearchCourseForLeadComponent } from "./LeadOfferunits/search-course-for-lead/search-course-for-lead.component";
import { NgxPrintModule } from "ngx-print";

@NgModule({
  declarations: [
    LeadRouteHandlerComponent,
    AustraliaServiceMovementComponent,
    StudentOverviewComponent,
    ApplicationsPerUserComponent,
    OfferLetterDocumentsComponent,
    VisaDocumentsComponent,
    StudyGapEmploymentComponent,
    IeltsPteDocsComponent,
    AddpassportComponent,
    AddEducationComponent,
    CommentsComponent,
    DemoFileUploadComponent,
    SponsorAffidavitComponent,
    SponsorIdComponent,
    SponsorIncomeProofComponent,
    SponsorPropertyDocumentsComponent,
    AddemploymentComponent,
    SearchCourseForLeadComponent
  ],
  imports: [CommonModule, LeadOffersRoutingModule, FormsModule, NgxPrintModule],
})
export class LeadOffersModule {}
