import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CRMLeadServiceService } from 'src/app/Services/leadAPI/crmlead-service.service';
import { environment } from '@env';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-visa-documents',
  templateUrl: './visa-documents.component.html',
  styleUrls: ['./visa-documents.component.scss']
})
export class VisaDocumentsComponent implements OnInit {
  apiLink = environment.production;
  addSponsorAffidavit = false;
  addSponsorId = false;
  addSponsorIncome = false;
  addSponsorProperty = false;
  leadId : any;

  sponsorDocs : Observable<any>;
  constructor(private route : ActivatedRoute, private leadApi : CRMLeadServiceService) { }

  ngOnInit(): void {
    this.leadId = this.route.snapshot.params['leadId'] 
    console.log("ABC:"+this.leadId)
    this.getSponsorDocuments()
  }

  submitForm(form:NgForm){
    
  }

  setSponsorAffidavit(){
    this.addSponsorAffidavit = true;
    this.addSponsorId = false;
    this.addSponsorIncome = false;
    this.addSponsorProperty = false;
  }

  setSponsorId(){
    this.addSponsorAffidavit = false;
    this.addSponsorId = true;
    this.addSponsorIncome = false;
    this.addSponsorProperty = false;
  }

  setSponsorIncome(){
    this.addSponsorAffidavit = false;
    this.addSponsorId = false;
    this.addSponsorIncome = true;
    this.addSponsorProperty = false;
  }

  setSponsorProperty(){
    this.addSponsorAffidavit = false;
    this.addSponsorId = false;
    this.addSponsorIncome = false;
    this.addSponsorProperty = true;
  }


  getSponsorDocuments(){
    this.leadApi.getSponsorData(this.leadId).subscribe(
      data => 
      {
        this.sponsorDocs = data
      console.log(data)
    }
    )
  }

 
  
}
