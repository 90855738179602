<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Applications</title>
  </head>
  <body>
    <div class="container-fluid applicationsTable">
      <div class="card shadow">
        <div class="card-header">
          Applications
        </div>
        <div class="card-body">
          <table border="1" class="table table-hover table-primary">
            <thead>
              <tr>
                <th scope="col">APP ID #</th>
                <th scope="col">SCHOOL</th>
                <th scope="col">COURSE</th>
                <th scope="col">ADDRESS</th>
                <th scope="col">DURATION</th>
                <th scope="col">INTAKE</th>
                <th scope="col">YEAR</th>
                <th scope="col">Status</th>
                <th scope="col">OFFER LETTER</th>
                <th scope="col">FEES</th>
                <th scope="col">COE</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let appli of applicationsApplied; let i = index">
                <tr >
                  <th scope="row">{{ i + 1 }}</th>
                  <td>{{ appli.courseToCourseIntake.course.college.name }}</td>
                  <td>{{ appli.courseToCourseIntake.course.courseName }}</td>
                  <td>
                    {{
                      appli.courseToCourseIntake.course.college.collegeState.stateName
                    }}
                  </td>
                  <td>{{ appli.courseToCourseIntake.course.courseDuration }}</td>
                  <td>{{ appli.courseToCourseIntake.courseIntake.intakeMonth }}</td>
                  <td>{{ appli.courseToCourseIntake.courseIntake.intakeYear }}</td>
                  <td>{{ appli.appStatus}}</td>
                  <td>
                    <i
                      class="fas fa-cloud-download-alt mr-2"
                      (click)="downloadOfferLetter()"
                    ></i>
                  </td>
                  <td>
                    <a
                      data-toggle="modal"
                      data-target="#feesLaunch"
                      data-backdrop="static"
                      data-keyboard="false"
                      (click)="leadApplicationId(appli.id)"
                      ><i class="fas fa-cloud-download-alt mr-2"></i
                    ></a>
                  </td>
                  <td><i class="fas fa-cloud-download-alt mr-2"></i></td>
                </tr>
                <tr>
                  <td colspan="11">
                    <table border="1" class="table table-hover table-primary">
                      <thead>
                        <tr>
                          <th scope="col" *ngFor="let tableStruct of tableStructure">
                            {{ tableStruct.key }}
                          </th>
                          <th scope="col">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let feeDetails of appli.leadApplicationFeeDetail">
                          <td scope="row" *ngFor="let tableStruct of tableStructure">
                            {{ getValueFromNestedObject(feeDetails, tableStruct.value) }}
                          </td>
                          <td class="actionsTd">
                            <ul class="MiniHorzNav">
                              <li>
                                <i data-toggle="modal"
                                data-target="#feeFileLaunch"
                                data-backdrop="static"
                                data-keyboard="false" (click)="createFeeSlipSrc('https://6c26-223-130-28-90.ngrok-free.app/document?id=' + feeDetails.feeSlipDoc)" class="fas fa-eye fa-xl"></i>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </ng-container>
              
            </tbody>
          </table>
        </div>
      </div>
      
    </div>
    
    <div class="container">
      <div class="row">
        <div class="col-auto mx-auto">
          <button
            class="btn btn-outline-danger px-2"
            data-toggle="modal"
            data-target="#exampleModalLong"
            data-backdrop="static"
            data-keyboard="false"
            (click)="viewCourseSearch()"
          >
            Apply more...
          </button>
        </div>
      </div>
    </div>
    <div class="container" *ngIf="viewCourseSea">
      <app-search-course-for-lead
        [leadId]="leadId"
      ></app-search-course-for-lead>
    </div>
    <!-- <div [hidden]="viewLoader" class="spinner-border positionSpinner"></div> -->
    <button hidden (click)="getApplicationsApplied()" id="getApplications">
      Click me
    </button>
  </body>
</html>
<div
  class="modal fade"
  id="feesLaunch"
  style="padding-right: 5px"
  role="dialog"
  aria-labelledby="invoiceModalTitle"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-xl modal-side modal-top-right"
    role="navigation"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Fee Details</h5>
        <button
          type="button"
          id="closeButtonInvoice"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #feesDetailsForm="ngForm" (submit)="postLeadFeeDetails(feesDetailsForm)">
          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="feesPaid">Amount Paid</label>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  aria-label="Amount (to the nearest dollar)"
                  name="feesPaid"
                  ngModel
                />
                <div class="input-group-append">
                  <span class="input-group-text">$</span>
                </div>
              </div>
            </div>
            <div class="form-group col-md-4">
              <label for="paymentDate">Paid On</label>
              <input
                autocomplete="off"
                type="date"
                name="paymentDate"
                class="form-control"
                placeholder="Task header"
                ngModel
                required
              />
            </div>
            <div class="form-group col-md-4">
              <label for="referenceId">Reference Id</label>
              <input
                autocomplete="off"
                type="text"
                name="referenceId"
                class="form-control"
                placeholder="Task header"
                ngModel
                required
              />
            </div>
            <div class="form-group col-md-4">
              <label for="paymentMethod">Payment Method</label>
              <input
                autocomplete="off"
                type="text"
                name="paymentMethod"
                class="form-control"
                placeholder="Task header"
                ngModel
                required
              />
            </div>
            <div class="form-group col-md-4">
              <label for="verificationStatus">Verification Status</label>
              <input
                autocomplete="off"
                type="text"
                name="verificationStatus"
                class="form-control"
                placeholder="Task header"
                ngModel
                required
              />
            </div>
            <div class="form-group col-md-4">
              <label for="Name">Fee Slip (PDF format)</label>
              <input
                type="file"
                name="educationFile"
                class="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-default"
                (change)="onChange($event)"
              />
            </div>
          </div>
          <div class="form-row">
            <button class="btn btn-primary mx-auto" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="feeFileLaunch"
  style="padding-right: 5px"
  role="dialog"
  aria-labelledby="invoiceModalTitle"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-xl modal-side modal-top-right"
    role="navigation"
  >
    <div class="modal-content">
      <div class="modal-header">
        Fee Slip
        <button
          type="button"
          id="closeButtonInvoice"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="width: 100%; height: 80vh;" *ngIf="feeSlipSrc != null">
        <iframe style="height: 100%; width: 100%;"  src='https://260e-223-130-28-90.ngrok-free.app/document?id=581/application/7ac8c556-47ac-4aaf-8925-fb37e7223ac9.pdf' frameborder="0"></iframe>
      </div>
    </div>
  </div>
</div>