<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Add Passport</title>
  </head>
  <body>
    <div class="container">
      <div class="card">
        <div class="card-body">
          <form #passportForm="ngForm" (ngSubmit)="addPassportDetails(passportForm)">
            <div class="row">
              <div class="col-2"></div>
              <div class="col-3">
                <label for="uName">Name</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    name="uName"
                    class="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    ngModel
                  />
                </div>
              </div>
              <div class="col-2"></div>
              <div class="col-3">
                <label for="PassportNo">Passport No.</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    name="PassportNo"
                    class="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    ngModel
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-2"></div>
              <div class="col-3">
                <label for="issueDate">Date Of Issue</label>
                <div class="input-group mb-3">
                  <input
                    type="date"
                    name="issueDate"
                    class="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    ngModel
                  />
                </div>
              </div>
              <div class="col-2"></div>
              <div class="col-3">
                <label for="expiryDate">Date Of Expiry</label>
                <div class="input-group mb-3">
                  <input
                    type="date"
                    name="expiryDate"
                    class="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    ngModel
                  />
                </div>
              </div>
            </div>
            <div class="row">
                <div class="col-4"></div>
                <div class="col-4">
                  <label for="passportFile">Passport</label>
                  <div class="input-group mb-3">
                    <input
                    type="file"
                    name="passportFile"
                    class="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    (change)="onChange($event)"
                    />
                  </div>
                </div>
                <div class="col-4"></div>
              </div>
            <div class="row">
              <div class="col-3"></div>
              <div class="col-2">
                <button class="mx-auto btn btn-outline-primary">Cancel</button>
              </div>
              <div class="col-3"></div>
              <div class="col-2">
                <button [disabled]="!viewLoader" class="mx-auto btn btn-primary">Save</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- <div [hidden]="viewLoader" class="spinner-border positionSpinner"></div> -->
  </body>
</html>
