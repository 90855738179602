import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CRMLeadServiceService } from "src/app/Services/leadAPI/crmlead-service.service";
import { jsPDF } from "jspdf";
import { AdminService } from "src/app/Services/adminService/admin.service";
import { NgForm } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-applications-per-user",
  templateUrl: "./applications-per-user.component.html",
  styleUrls: ["./applications-per-user.component.scss"],
})
export class ApplicationsPerUserComponent implements OnInit {
  tableStructure = [
    { key: "Id", value: "id" },
    { key: "Fees Paid", value: "feesPaid" },
    { key: "Payment Date", value: "paymentDate" },
    { key: "Payment Method", value: "paymentMethod" },
    { key: "Verification Status", value: "verificationStatus" },
    { key: "Reference Number", value: "feeReferenceNumber" }
  ]; 

  file: any;
  leadApplicationI: any;

  feeSlipSrc: any;

  constructor(
    private route: ActivatedRoute,
    private leadApi: CRMLeadServiceService,
    private adminService: AdminService,
    private toastrService: ToastrService,
    private sanitizer: DomSanitizer
  ) {}
  leadId: number;
  viewCourseSea: boolean = false;
  applicationsApplied: any = [];
  viewLoader: boolean = true;

  ngOnInit(): void {
    this.leadId = this.route.snapshot.params["leadId"];
    console.log(this.leadId);
    this.getApplicationsApplied();
  }

  viewCourseSearch() {
    if (this.viewCourseSea) {
      this.viewCourseSea = false;
    } else {
      this.viewCourseSea = true;
    }
  }

  getApplicationsApplied() {
    this.viewLoader = false;
    this.leadApi.getLeadApplications(this.leadId).subscribe(
      (data) => {
        this.applicationsApplied = data;
        console.log(data);
        this.viewLoader = true;
      },
      (err) => {
        alert("Some issue occured, Please contact admin");
        this.viewLoader = true;
      },
      () => {
        console.log("Get applications successfully executed.");
      }
    );
  }

  downloadOfferLetter() {
    const doc = new jsPDF();

    doc.text("Hello world!", 10, 10);
    doc.save("a4.pdf");
    let obj = {
      leadApplicationId: this.leadId,
    };
    this.adminService.postOfferLetterGenerated(obj).subscribe((data) => {
      console.log(data);
    });
  }

  onChange(event) {
    this.file = event.target.files[0];
  }

  postLeadFeeDetails(form: NgForm) {
    const formData = new FormData();

    formData.append("file", this.file);
    formData.append("feesPaid", form.value.feesPaid);
    formData.append("paymentDate", form.value.paymentDate);
    formData.append("feeReferenceNumber", form.value.referenceId);
    formData.append("paymentMethod", form.value.paymentMethod);
    formData.append("verificationStatus", form.value.verificationStatus);
    formData.append("leadApplicationId", this.leadApplicationI);

    this.leadApi.postLeadFeeDetailsAndSlip(formData).subscribe((data) => {
      this.toastrService.success("Fees successfully submitted")
    });
  }

  leadApplicationId(leadApplicationId){
    this.leadApplicationI = leadApplicationId;
  }

  getValueFromNestedObject(obj: any, path: string): any {
    const keys = path.split('.');
    return keys.reduce((o, key) => (o && o[key] !== 'undefined' ? o[key] : ''), obj);
  }

  createFeeSlipSrc(src){
    this.feeSlipSrc = this.sanitizer.bypassSecurityTrustResourceUrl(src);
  }
}
