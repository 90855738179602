import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { data } from 'jquery';
import { EvenEmitForRefreshService } from 'src/app/Services/EventEmitForRefresh/even-emit-for-refresh.service';
import { CRMLeadServiceService } from 'src/app/Services/leadAPI/crmlead-service.service';
import { environment } from '@env';

@Component({
  selector: 'app-offer-letter-documents',
  templateUrl: './offer-letter-documents.component.html',
  styleUrls: ['./offer-letter-documents.component.scss']
})
export class OfferLetterDocumentsComponent implements OnInit {

  apiLink = environment.production;
  addPassportView = false;
  addEducationView = false;
  addIeltsPTEView = false;
  addStudyGapView = false;
  addEmploymentView = false;
  toRefresh = true;

  studyGap : any;

  leadId : any;

  passports : any;
  academics : any;
  ielts : any;
  employs: any;
  constructor(private leadApi:CRMLeadServiceService, private route : ActivatedRoute, private eventEmitterService: EvenEmitForRefreshService, public router : Router) { 
    this.leadId = this.route.snapshot.params['leadId']

  }
  ngOnInit(): void {
    //document.getElementById("closeButton").click();

    this.getIeltsPte();
    this.getEducationDetails();
    this.getStudyGap(this.leadId)
    this.getPassportDetails(this.leadId)
    this.getEmployment()

  }

  setPassportView(){
    this.addEducationView = false;
    this.addIeltsPTEView = false;
    this.addStudyGapView = false;      
    this.addPassportView = true;
    this.addEmploymentView = false;
    this.toRefresh = true;
  }

  setEducationView(){
    this.addPassportView = false;
    this.addIeltsPTEView = false;
    this.addStudyGapView = false;      
    this.addEducationView = true;
    this.addEmploymentView = false;
    this.toRefresh = true;
  }

  setIeltsPteView(){
    this.addPassportView = false;
    this.addEducationView = false;
    this.addStudyGapView = false;      
    this.addIeltsPTEView = true;
    this.addEmploymentView = false;
    this.toRefresh = true;

  }

  setStudyGapView(){
    this.addPassportView = false;
    this.addEducationView = false;
    this.addIeltsPTEView = false;
    this.addStudyGapView = true;   
    this.addEmploymentView = false;
    this.toRefresh = true;

  }

  setEmploymentView(){
    this.addPassportView = false;
    this.addEducationView = false;
    this.addIeltsPTEView = false;
    this.addStudyGapView = false;   
    this.addEmploymentView = true;
    this.toRefresh = true;

  }


  //get study gap
  getStudyGap(leadId: string){
    this.leadApi.getStudyGap(leadId).subscribe(
      data =>{
        this.studyGap = data
        console.log(data)
      },
      err =>{
        console.log(err)
      },
      ()=>{
        console.log("study gap docs fetched successfully");
      }
    )
  }

  getPassportDetails(leadId : string){
    this.leadApi.getPassport(leadId).subscribe(
      data => this.passports = data,
      err=>{},
      () => console.log("Passport fetched")
    )
  }

  getEducationDetails(){
    this.leadApi.getEducation(this.leadId).subscribe(
      data => 
      {
        this.academics = data
        console.log(data)
      }
    )
  }

  getIeltsPte(){
    this.leadApi.getIeltsPte(this.leadId).subscribe(
      data => {
      console.log(data)
      this.ielts = data;
      }
    )
  }

  getEmployment(){
    this.leadApi.getEmployment(this.leadId).subscribe(    
      data => {
        this.employs = data
        console.log(data)
      }
      )
  }
  downloadPdf(documentId:string){
    this.leadApi.downloadPdf(documentId).subscribe(
      data => console.log(data)
    )
  }


}
