
    <div class="container">
      <div class="row">
        <div class="col-4"></div>
        <button
          class="col-4 btn btn-outline-danger"
          data-toggle="modal"
          data-target="#exampleModalLong"
          data-backdrop="static"
          data-keyboard="false"
          
        >
          Sponsor Document
        </button>
        

      <!-- <table border="1" class="table table-hover table-primary">
        <thead>
          <tr>
            <th scope="col">STATUS</th>
            <th scope="col">APP ID #</th>
            <th scope="col">SCHOOL</th>
            <th scope="col">COURSE</th>
            <th scope="col">DURATION</th>
            <th scope="col">INTAKE</th>
            <th scope="col">YEAR</th>
            <th scope="col">OFFER LETTER</th>
            <th scope="col">COE</th>
            <th scope="col">VISA</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Not yet applied</th>
            <td>Not yet applied</td>
            <td>Not yet applied</td>
            <td>Not yet applied</td>
            <td>1 Year</td>
            <td>Sept-May</td>
            <td>2020</td>
            <td><i class="fas fa-cloud-download-alt mr-2"></i></td>
            <td><i class="fas fa-cloud-download-alt mr-2"></i></td>
            <td><i class="fas fa-cloud-download-alt mr-2"></i></td>
          </tr>
        </tbody>
      </table> -->
  
        <!-- <button
          class="col-3 btn btn-outline-danger"
          data-toggle="modal"
          data-target="#exampleModalLong"
          data-backdrop="static"
          data-keyboard="false"
          (click)="setSponsorId()"
        >
          Sponsor ID's
        </button> -->
        <div class="col-4"></div>
      </div>
      <!-- <table border="1" class="table table-hover table-primary">
        <thead>
          <tr>
            <th scope="col">STATUS</th>
            <th scope="col">APP ID #</th>
            <th scope="col">SCHOOL</th>
            <th scope="col">COURSE</th>
            <th scope="col">DURATION</th>
            <th scope="col">INTAKE</th>
            <th scope="col">YEAR</th>
            <th scope="col">OFFER LETTER</th>
            <th scope="col">COE</th>
            <th scope="col">VISA</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Not yet applied</th>
            <td>Not yet applied</td>
            <td>Not yet applied</td>
            <td>Not yet applied</td>
            <td>1 Year</td>
            <td>Sept-May</td>
            <td>2020</td>
            <td><i class="fas fa-cloud-download-alt mr-2"></i></td>
            <td><i class="fas fa-cloud-download-alt mr-2"></i></td>
            <td><i class="fas fa-cloud-download-alt mr-2"></i></td>
          </tr>
        </tbody>
      </table> -->
      <!-- <div class="row">
        <div class="col-2"></div>
        <button
          class="col-3 btn btn-outline-danger"
          data-toggle="modal"
          data-target="#exampleModalLong"
          data-backdrop="static"
          data-keyboard="false"
          (click)="setSponsorIncome()"
        >
          Sponsor Income Proof
        </button>
        <div class="col-2"></div> -->

      <!-- <table border="1" class="table table-hover table-primary">
        <thead>
          <tr>
            <th scope="col">STATUS</th>
            <th scope="col">APP ID #</th>
            <th scope="col">SCHOOL</th>
            <th scope="col">COURSE</th>
            <th scope="col">DURATION</th>
            <th scope="col">INTAKE</th>
            <th scope="col">YEAR</th>
            <th scope="col">OFFER LETTER</th>
            <th scope="col">COE</th>
            <th scope="col">VISA</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Not yet applied</th>
            <td>Not yet applied</td>
            <td>Not yet applied</td>
            <td>Not yet applied</td>
            <td>1 Year</td>
            <td>Sept-May</td>
            <td>2020</td>
            <td><i class="fas fa-cloud-download-alt mr-2"></i></td>
            <td><i class="fas fa-cloud-download-alt mr-2"></i></td>
            <td><i class="fas fa-cloud-download-alt mr-2"></i></td>
          </tr>
        </tbody>
      </table> -->
    
        <!-- <button
          class="col-3 btn btn-outline-danger"
          data-toggle="modal"
          data-target="#exampleModalLong"
          data-backdrop="static"
          data-keyboard="false"
          (click)="setSponsorProperty()"
        >
          Sponsor Property Documents
        </button>
        <div class="col-2"></div>

      </div> -->
      <table border="1" class="table table-hover table-primary">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Sponsor Name</th>
            <th scope="col">Type of document</th>
            <th scope="col">Download</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let spo of sponsorDocs; let i = index">
            <th scope="row">{{i+1}}</th>
            <td>{{spo.sponserName}}</td>
            <td>{{spo.documentType}}</td>
            <td><a href="{{apiLink}}document?id={{spo.documentId}}" target="_blank"><i class="	fas fa-cloud-download-alt mr-2"></i></a></td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Add Visa details modal for Sponsor decalaration/ Affidavits -->
    <div
      class="modal fade"
      id="exampleModalLong"
      style="padding-right: 5px"
      data-modal-width="99vw"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-xl modal-side modal-top-right"
        role="document"
      >
        <div id="print-section" class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Visa Documents
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              (click)="getSponsorDocuments()"
              id="closeButton"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
            <div class="modal-body">
              <div class="container-fluid">
                <div>
                  <!-- <div *ngIf="addSponsorAffidavit" class="card-header">
                    <h6 class="m-0 text-dark">Sponsor Affidavit</h6>
                  </div>
                  <div *ngIf="addSponsorId" class="card-header">
                    <h6 class="m-0 text-dark">Sponsor Identification ID</h6>
                  </div>
                  <div *ngIf="addSponsorIncome" class="card-header">
                    <h6 class="m-0 text-dark">Sponsor Income</h6>
                  </div>
                  <div *ngIf="addSponsorProperty" class="card-header">
                    <h6 class="m-0 text-dark">Sponsor Property</h6>
                  </div> -->
                  <div class="card-body">
                    <app-sponsor-affidavit
                      [leadId]="leadId"
                    ></app-sponsor-affidavit>
                  </div>
                  <!-- <div *ngIf="addSponsorId" class="card-body">
                    <app-sponsor-id [leadId]="leadId"></app-sponsor-id>
                  </div>
                  <div *ngIf="addSponsorIncome" class="card-body">
                    <app-sponsor-income-proof
                      [leadId]="leadId"
                    ></app-sponsor-income-proof>
                  </div>
                  <div *ngIf="addSponsorProperty" class="card-body">
                    <app-sponsor-property-documents
                      [leadId]="leadId"
                    ></app-sponsor-property-documents>
                  </div> -->
                </div>
              </div>
            </div>
  
        </div>
      </div>
    </div>
    <!-- End Affidavit modal -->