import { Component, OnInit, Input } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CRMLeadServiceService } from "src/app/Services/leadAPI/crmlead-service.service";

@Component({
  selector: "app-addpassport",
  templateUrl: "./addpassport.component.html",
  styleUrls: ["./addpassport.component.scss"],
})
export class AddpassportComponent implements OnInit {
  @Input() leadId = "";

  viewLoader: boolean = true;
  constructor(
    private leadApi: CRMLeadServiceService,
    private route: ActivatedRoute,
    private toastService: ToastrService
  ) {}

  file: File;
  // leadId : any;
  ngOnInit(): void {
    // this.leadId = this.route.snapshot.params['leadID']
    console.log(this.leadId);
  }

  onChange(event) {
    this.file = event.target.files[0];
  }

  addPassportDetails(form: NgForm) {
    this.viewLoader = false;
    const formData = new FormData();

    formData.append("file", this.file);
    formData.append("passportId", form.value.PassportNo);
    formData.append("name", form.value.uName);
    formData.append("issueDate", form.value.issueDate);
    formData.append("expiryDate", form.value.expiryDate);
    formData.append("leadId", this.leadId);

    console.log(formData);
    this.leadApi.createPassport(formData).subscribe(
      (data) => {
        console.log(data);
        this.toastService.success("Passport document uploaded successfully");
        this.viewLoader = true;
        document.getElementById("closeButton").click();
      },
      (err) => {
        console.log(err);
        this.viewLoader = true;
      },
      () => console.log("passport add successfully executed.")
    );
  }
}
