<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Document</title>
  </head>
  <body>
    <div class="container">
      <div class="card">
        <div class="card-body">
          <form #ieltsForm="ngForm" (ngSubmit)="addIeltsDetails(ieltsForm)">
            <div class="row">
              <div class="col-2"></div>
              <div class="col-3">
                <label for="meetingEnglish">How do you intend on meeting the english language requirement for this program?</label>
                <select class="form-select mb-3" name="meetingEnglish" ngModel>
                  <option value="IELTS">IELTS</option>
                  <option value="PTE">PTE</option>
                  <option value="OTHER">OTHER</option>
                </select>
              </div>
              <div class="col-2"></div>
              <div class="col-3">
                <label for="proficiency">What is your proficiency test type?</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    name="proficiency"
                    ngModel
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-2"></div>
              <div class="col-3">
                <label for="completionDate">Test completion date</label>
                <div class="input-group mb-3">
                  <input
                    type="date"
                    class="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    name="completionDate"
                    ngModel
                  />
                </div>
              </div>
              <div class="col-2"></div>
              <div class="col-3">
                <label for="overallBand">Overall Band</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    name="overallBand"
                    ngModel
                  />
                </div>
              </div>
            </div>
            <div class="row">
                <div class="col-2"></div>
                <div class="col-3">
                    <label for="listening">Listening</label>
                    <div class="input-group mb-3">
                        <input
                        type="text"
                        class="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-default"
                        name="listening"
                        ngModel
                        />
                </div>
            </div>
              <div class="col-2"></div>
              <div class="col-3">
                  <label for="speaking">Speaking</label>
                  <div class="input-group mb-3">
                      <input
                      type="text"
                      class="form-control"
                      aria-label="Sizing example input"
                      aria-describedby="inputGroup-sizing-default"
                      name="speaking"
                      ngModel
                      />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-2"></div>
                <div class="col-3">
                    <label for="reading">Reading</label>
                    <div class="input-group mb-3">
                        <input
                        type="text"
                        class="form-control"
                        aria-label="Sizing example input"
                        aria-describedby="inputGroup-sizing-default"
                        name="reading"
                        ngModel
                        />
                </div>
            </div>
              <div class="col-2"></div>
              <div class="col-3">
                  <label for="writing">Writing</label>
                  <div class="input-group mb-3">
                      <input
                      type="text"
                      class="form-control"
                      aria-label="Sizing example input"
                      aria-describedby="inputGroup-sizing-default"
                      name="writing"
                      ngModel
                      />
                    </div>
                </div>
            </div>
            <div class="row">
              <div class="col-4"></div>
              <div class="col-4">
                <label for="ieltsPteFile">Certificate</label>
                <div class="input-group mb-3">
                  <input
                  type="file"
                  name="ieltsPteFile"
                  class="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-default"
                  (change)="onChange($event)"
                  />
                </div>
              </div>
              <div class="col-4"></div>
            </div>
            <div class="row">
                
                    <button [disabled]="!viewLoader" class="mx-auto btn btn-primary">Save</button>
                
            </div>
    </form>
        </div>
      </div>
    </div>
    <!-- <div [hidden]="viewLoader" class="spinner-border positionSpinner"></div> -->
  </body>
</html>
