<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Document</title>
  </head>
  <body>
    <div class="container">
      <div class="card">
        <div class="card-header">
          <h5>Study Gaps</h5>
        </div>
        <div class="card-body">
          <form #studyGap="ngForm" (ngSubmit)="addStudyGap(studyGap)">
            <div class="row">
              <div class="col-2"></div>
              <div class="col-3">
                <label for="studyGapSelect">Do you have any study gap</label>
                <br/>
                <select class="form-select mb-3" name="isStudyGap" ngModel>
                  <option value=1>Yes</option>
                  <option value=0>No</option>
                </select>
              </div>
              <div class="col-2"></div>
              <div class="col-3">
                <label for="gapReason">Reason for study gap</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    name="gapReason"
                    ngModel
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-2"></div>
              <div class="col-3">
                <label for="studyGapSelect">Start date</label>
                <div class="input-group mb-3">
                  <input
                    type="date"
                    class="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    name="gapStartDate"
                    ngModel
                  />
                </div>
              </div>
              <div class="col-2"></div>
              <div class="col-3">
                <label for="studyGapSelect">End date</label>
                <div class="input-group mb-3">
                  <input
                    type="date"
                    class="form-control"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-default"
                    name = "gapEndDate"
                    ngModel
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-4"></div>
              <div class="col-4">
                <label for="file">Gap Document</label>
                <div class="input-group mb-3">
                  <input
                  type="file"
                  name="file"
                  class="form-control"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-default"
                  (change)="onChange($event)"
                  ngModel
                  />
                </div>
              </div>
              <div class="col-4"></div>
            </div>
            <div class="row">
            <button [disabled]="!viewLoader" type="submit"  class="mx-auto btn btn-primary">Save</button>
          </div>
          </form>
        </div>
      </div>
    </div>
    <!-- <div [hidden]="viewLoader" class="spinner-border positionSpinner"></div>  -->
  </body>
</html>
